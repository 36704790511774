/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import axios from 'axios'
import useAppConfig from '@core/app-config/useAppConfig'
import dashboard from './dashboard'
import appsAndPages from './apps-and-pages'
import others from './others'
import chartsAndMaps from './charts-and-maps'
import uiElements from './ui-elements'
import formAndTable from './forms-and-table'

async function fetchAppsAndPages() {
    // try {
    //     const params = new URLSearchParams()
    //     params.append('emp_id', localStorage.getItem('id'))
    //     const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/hak-akses/view`, params)
    //     // const transformedAppsAndPages = result.data.data.map(item => ({
    //     //     ...item, // salin semua properti yang ada
    //     //     title: item.title || '', // set nilai icon jika belum ada
    //     //     icon: item.icon || 'MonitorIcon', // set nilai icon jika belum ada
    //     //     children: item.children || [], // pastikan children selalu berupa array
    //     //     route: item.route || '',
    //     // }))

    //     localStorage.setItem('menu', JSON.stringify(result.data.data))
    //     // localStorage.setItem('menu', JSON.stringify(appsAndPages))

    //     return result
    // } catch (error) {
    //     console.error('Error fetching appsAndPages:', error)
    //     return []
    // }
}
fetchAppsAndPages()
export default [...dashboard, ...JSON.parse(localStorage.getItem('menu')), ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
